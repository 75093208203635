<template>
    <r-e-dialog title="证件照片" class="layer-contract-detail" :visible.sync="dialogVisible" show-footer  :height="800"
    @click-submit="clickCancel" @click-cancel="clickCancel" @close="clickCancel">
        <!-- <el-form ref="formPublish" label-width="90px" size="small"> -->
            <div style="height:20px"></div>
            <div class="flex" style="height: 180px;overflow:scroll;flex-wrap:wrap" >
                <div v-for="(item,index) in imgList" :key="index">
                    <div style="background: #f5f7fa;width: 225px;height: 150px; display: flex; justify-content: center;border: 1px solid #F1F1F3;border-radius: 5px;margin: 15px">
                        <el-image :src="createFullImageUrl(item)" style="display:flex;align-items: center;" @click="look(createFullImageUrl(item))">
                            <div slot="error" style="font-size: 30px;">
                                <i class="el-icon-picture-outline"></i>
                            </div>
                            <div slot="placeholder">加载中...</div>
                        </el-image>
                    </div>
                </div>
            </div>
            <div style="height:20px"></div>
            <!-- <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
            </span> -->
        <!-- </el-form> -->

        <el-dialog :visible.sync="lookDialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="">
        </el-dialog>

    </r-e-dialog>
</template>

<script>
    import {createFullImageUrl} from "@/components/Upload/index"
    export default {
        name: "dialog-look-activity-file",
        data(){
            return{
                dialogVisible: false,
                filePDFList:[],
                imgList:[],
                lookDialogVisible:false,
                dialogImageUrl:''
            }
        },
        props: {},
        methods:{
            openDialog(data){
                let {businessLicense} = data;
                console.log('datatupian',businessLicense);
                // let imgList = businessLicense !== null && businessLicense !== "" ? businessLicense.split(";").filter(item => item !== "")[0] : [];

                // let filePDFList = accessoryFile !== null && accessoryFile !== "" ? accessoryFile.split("+-+").filter(item => item !== "")[1] : [];
                this.imgList = businessLicense.split(";");
                console.log('imgList', this.imgList);
                // this.filePDFList = filePDFList.length !== 0 ? filePDFList.split(";") : [];
                this.dialogVisible = true;
            },
            createFullImageUrl(uuid){
                return createFullImageUrl(uuid)
            },
            look(url){
                this.lookDialogVisible = true;
                this.dialogImageUrl = url;
            },
            clickCancel(){
                this.dialogVisible = false;
            }
        }
    }
</script>

<style scoped>

</style>